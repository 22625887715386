<template>
  <div class="margins1">
    <h3>Топчик часов текущего месяца</h3>
    <template v-for="(item, i) in list">
      <div :key="i">{{ item.hours }}&mdash;{{ item.nickname }}&nbsp;({{ item.name }})</div>
    </template>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  props: {
    year: Number,
    month: Number,
    n: Number,
  },
  data() {
    return {
      list: [],
    };
  },

  async created() {
    await this.tableDataRetrieve();
  },

  methods: {
    async tableDataRetrieve() {
      this.list = await request("/api/report/topn", "POST", {
        year: this.year,
        month: this.month,
        n: this.n,
      });
    },
  },
};
</script>
