<template>
  <div v-if="this.$root?.user?.roles.includes('admin')">
    <TopN :year="new Date().getFullYear()" :month="new Date().getMonth() + 1" :n="5" />
    <PointAdminStats />
  </div>
</template>

<script>
import TopN from "@/components/TopN.vue";
import PointAdminStats from "@/components/PointAdminStats.vue";
export default {
  components: { TopN, PointAdminStats },
};
</script>